.three-columns {
  padding: 0;

  [class*="col-"] {
    padding-bottom: 15px;
  }

  a {
    text-decoration: none;
  }

  &__title,
  &__content {
    margin: 30px 0;
  }

  &__item {
    position: relative;
    display: block;
    overflow: hidden;
    background: #efefef;
    transition: 0.5s transform 0s linear;
    min-height: 410px;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a.three-columns__item {
    &:hover {
      .three-columns__img {
        transform: scale(1.1);

        &::before {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  &__img {
    @include cover-img;
    transition: $transition;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
      transition: $transition;
    }
  }

  &__box {
    position: relative;
    color: white;
    padding: 100px 20px;
    text-align: center;
    z-index: 2;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }

  &__big-text {
    padding: 20px;
    height: 100%;
    background: #efefef;
  }

  &__button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 30px;
    width: 60%;
  }
}

@include media-breakpoint-up(md) {
  .three-columns {
    [class*="col-"] {
      padding: 15px;
    }

    &__item {
      height: 100%;
    }
  }
}
