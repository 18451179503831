@mixin cover-img{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@mixin clearfix {
    &::after{
        content: "";
        display: block;
        clear: both;
    }
}
