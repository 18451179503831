.person {
  &__avatar {
    width: 100px;
    height: 100px;
    border: 1px solid #707070;
    overflow: hidden;
    display: table;
    margin: 20px auto;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__item {
    margin: 40px 0;
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: 1.5rem;
  }

  &__position {
    text-align: center;
  }
}
