.contact-map {
  &__map {
    position: relative;
    margin-top: 40px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__circle {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $primary;
    transition: $transition;

    &--cz {
      top: 52.25%;
      left: 49.1%;
    }

    &--sk {
      top: 66.2%;
      left: 56.56%;
    }

    &:hover {
      transform: scale(1.5);
    }
  }

  textarea {
    height: 150px;
  }

  .btn-rounded {
    margin: 0 auto;
  }
}

@include media-breakpoint-up(md) {
  .contact-map {
    &__map {
      margin-top: 0;
    }

    &__circle {
      width: 12px;
      height: 12px;
    }
  }
}
