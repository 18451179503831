.btn-rounded {
  display: table;
  border-radius: $rounded-pill;
  text-align: center;
  padding: 0.5rem 4.5rem;
  margin: 0 auto;
  transition: $transition;
  border: 0;
  height: auto;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 2px;
    transform: rotate(60deg);
    background: currentColor;
    top: 16px;
    right: 25px;
    border-radius: 2px;
    opacity: 0;
    transition: $transition;
  }

  &:after {
    transform: rotate(-60deg);
    top: 24px;
  }

  &.btn-lg {
    &:before {
      top: 18px;
    }
    &:after {
      top: 26px;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;

    &:before,
    &:after {
      opacity: 1;
      right: 15px;
    }
  }

  // &--big {
  //   font-size: 1.2rem;
  //   padding: 10px 30px;

  //   &:before,
  //   &:after {
  //     display: none;
  //   }
  // }

  // &--primary-color {
  //   background: $primary;
  //   color: white;

  //   &:hover {
  //     color: white;
  //   }

  //   &:before,
  //   &:after {
  //     background: white;
  //   }
  // }
}

// a.button {
//   &:hover {
//     //color: white;
//   }
// }
