.side-nav {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 20px;

  &__item {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.25);
  }

  a {
    color: white;
    display: block;
    transition: $transition;

    &:hover {
      background: rgba($color: #ffffff, $alpha: 0.1);
      text-decoration: none;
    }
  }

  &__label {
    display: none;
  }

  &__icon {
    text-align: center;
    display: table;
    margin: 0 auto;
    padding: 20px 0;
  }
}

@include media-breakpoint-up(lg) {
  .side-nav {
    &__icon {
      position: relative;
      display: inline-block;
      top: 3px;
      margin: 0;
      margin-right: 8px;
      padding: 0;
    }

    &__label {
      display: inline-block;
      font-size: 16px;
    }

    a {
      padding: 10px 20px;
    }
  }
}
