.rotate-item {
	position: relative;
	background: transparent !important;
	overflow: visible !important;
	perspective: 1000px;

	&:hover {
		.rotate-item__item {
			transform: rotateY(180deg);

			.rotate-item {
				&__box {
					&--box1 {
						opacity: 0;
					}
					&--box2 {
						opacity: 1;
					}
				}
			}
		}
	}

	&__item {
		transition: 1s transform 0s linear;
		position: relative;
		height: 100%;
		width: 100%;
	}

	&__box {
		width: 100%;
		height: 100%;
		position: absolute;
		background: #efefef;
		transition: 0s opacity 0.5s linear;
		display: flex;
		align-items: center;
		justify-content: center;

		&--box1 {
			opacity: 1;
		}

		&--box2 {
			opacity: 0;
			transform: rotateY(180deg);
		}
	}
}
