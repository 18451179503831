.side-item {
  display: none;
  opacity: 0.2;

  &__item {
    position: absolute;
    max-width: 250px;

    &--right {
      right: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .side-item {
    display: block;
  }
}
