.breadcrumb {
  padding: 0;
  margin: 0;
  list-style-type: none;
  @include clearfix;
  margin-bottom: 10px;

  &-item {
    position: relative;
    float: left;
    margin-right: 20px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 5px;
      height: 1px;
      background: $primary;
      top: 11px;
      right: -12px;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
      top: 14px;
    }

    &.active {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
