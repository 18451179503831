.only-text {
  &__content {
    text-align: center;
    font-size: 1.4rem;
    margin: 20px 0;

    &--small {
      font-size: 1rem;
      line-height: 1.7;
    }
  }
}
