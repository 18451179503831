.logo {
  //float: left;
  //padding: 20px;
  width: 150px;
  //margin: 0;
  //margin-top: 5px;
  //height: 78px;
  position: relative;
  left: -10px;

  img {
    max-width: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .logo {
    //padding: 25px;
    width: 160px;
    //margin: 0;
    //height: 83px;
    // left: -10px;
  }
}
