.admin-controls {
	position: absolute;
	right: 0px;
	top: 25px;
	z-index: 99;
	//width: 100%;

	&__main-button {
		display: block;
		position: relative;
		width: 30px;
		height: 30px;
		background: white;
		border: 1px solid $primary;
		cursor: pointer;
		transition: $transition;
		float: right;
		padding: 3px;

		img {
			max-width: 100%;
			display: block;
		}
	}

	&__block {
		position: relative;
		clear: both;
		display: none;

		&--reveal {
			display: block;
		}
	}

	&__button {
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		background: white;
		border: 1px solid $primary;
		cursor: pointer;
		transition: $transition;
		float: right;
		margin: 3px;

		&:last-child {
			margin-left: 0;
		}

		&:hover {
			background: $color-primary-1;
		}

		&::before,
		&::after,
		span {
			content: "";
			display: block;
			position: absolute;
			top: 5px;
			left: 8px;
			width: 2px;
			height: 8px;
			background: $primary;
		}

		&--remove {
			&::before {
				transform: rotate(45deg);
			}
			&::after {
				transform: rotate(-45deg);
			}
		}

		&--add {
			&::after {
				transform: rotate(90deg);
			}
		}

		&--move-up {
			&::before {
				transform: rotate(45deg);
				left: 6px;
			}
			&::after {
				transform: rotate(-45deg);
				left: 10px;
			}
		}

		&--move-down {
			&::before {
				transform: rotate(-45deg);
				left: 6px;
			}
			&::after {
				transform: rotate(45deg);
				left: 10px;
			}
		}

		&--align-left,
		&--align-right,
		&--align-center,
		&--align-justify {
			&::before {
				transform: rotate(90deg);
				top: 1px;
			}
			&::after {
				transform: rotate(90deg);
				top: 9px;
			}

			span {
				transform: rotate(90deg);
				top: 3px;
				height: 12px;
			}
		}

		&--align-left {
			&::before,
			&::after {
				left: 6px;
			}
		}

		&--align-right {
			&::before,
			&::after {
				left: 10px;
			}
		}

		&--align-justify {
			&::before {
				height: 12px;
				top: -1px;
			}
			&::after {
				height: 12px;
				top: 7px;
			}
		}
	}

	&__select {
		display: block;
		position: relative;
		background: white;
		border: 1px solid $primary;
		cursor: pointer;
		transition: $transition;
		float: right;
		margin: 3px;
		padding: 0;
		list-style-type: none;
		font-size: 14px;
		height: 23px;

		li {
			position: relative;
			opacity: 0;
			visibility: hidden;
			text-align: center;

			a {
				position: relative;
				color: $primary;
				display: block;
				padding: 0 5px;

				&:hover {
					text-decoration: none;
					background: $color-primary-1;
				}
			}

			&:first-child {
				opacity: 1;
				visibility: visible;

				a,
				span {
					padding: 2px 20px 2px 5px;

					&::before,
					&::after {
						content: "";
						position: absolute;
						display: block;
						width: 6px;
						height: 2px;
						top: 10px;
						right: 5px;
						background: $primary;
						transform: rotate(-30deg);
					}

					&::after {
						transform: rotate(30deg);
						right: 10px;
					}
				}
			}
		}

		&:hover,
		&--active {
			height: auto;

			& li {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.m-t-20 {
	top: 20px;
}
.m-t-30 {
	top: 30px;
}
