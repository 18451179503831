.two-boxes {
  &__item {
    padding: 20px;
    margin: 20px 0;

    &--primary-color {
      color: white;
      background: $primary;
    }
  }
}

@include media-breakpoint-up(md) {
  .two-boxes {
    &__item {
      &--move-top {
        margin-top: 150px;
      }
    }
  }
}
