.slide-tile {
  &__box {
    &--box2 {
      position: absolute;
      left: 0;
      top: 100%;
      height: 100%;
      width: 100%;
      transition: 0.5s top 0s linear;
      z-index: 2;
    }

    ul {
      padding-left: 18px;
    }
  }

  &:hover {
    .slide-tile__box {
      &--box2 {
        top: 0;
      }
    }
  }
}
