.image-textbox {
  position: relative;

  &__img {
    @include cover-img;
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }

  &__content {
    position: relative;
    color: white;
    padding: 20px;
    margin: 15px 0;
    background: rgba($color: $primary, $alpha: 0.85);
    border-radius: $border-radius;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }

  &__title {
    font-size: 2rem;
    margin: 20px 0;
  }

  &__text {
    margin: 20px 0;
  }

  &__flashes {
    position: fixed;
    z-index: 99;
    left: 250px;
    top: 110px;
  }

  &__wrapper {
    position: relative;
  }

  .btn-rounded {
    margin: 40px auto 20px;
    display: block;
    max-width: 200px;
    color: $primary;
  }

  &--reverse {
    .image-textbox__content {
      float: left;
      background: rgba($color: $secondary, $alpha: 0.85);
    }

    .btn-rounded {
      color: $secondary;
    }
  }

  & + & {
    padding-top: 0;
  }
}

@include media-breakpoint-up(md) {
  .image-textbox {
    &__content {
      width: 66.66%;
      float: right;
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-textbox {
    &__content {
      width: 50%;
      top: 110px;
      transition: $transition;
    }

    & + & {
      padding-top: 160px;
    }

    & + section {
      padding-top: 160px;
    }

    .btn-rounded {
      margin-top: 80px;
    }

    &.appear {
      .image-textbox__content {
        transform: scale(0.5);
      }
    }
  }
}
