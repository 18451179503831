$element-width: 60px;

.admin-panel {
  position: fixed;
  top: 0;
  height: 100vh;
  width: $element-width;
  background: #333;
  z-index: 100;
  left: $element-width * -1;
  transition: $transition;
  color: white;

  &__clicker {
    position: absolute;
    width: 20px;
    height: 40px;
    left: $element-width;
    top: calc(50% - 20px);
    background: #333;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
    transition: $transition;
    z-index: 1000;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 1px;
      height: 10px;
      background: white;
      transform: rotate(-30deg);
      top: 12px;
      left: 9px;
    }

    &::after {
      transform: rotate(30deg);
      top: 20px;
    }
  }

  &__logo {
    position: relative;
    height: 83px;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px;

    img {
      position: absolute;
      display: block;
      max-width: 150px;
      top: 50%;
      transform: translateY(-50%);
      clip: rect(0, 37px, 37px, 0px);
    }
  }

  &--active {
    left: 0;

    .admin-panel__clicker {
      &::before {
        transform: rotate(30deg);
      }
      &::after {
        transform: rotate(-30deg);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  $element-width: 230px;

  .admin-panel {
    width: $element-width;
    left: $element-width * -1;

    &__logo {
      img {
        max-width: calc(100% - 20px);
        clip: auto;
      }
    }

    &__clicker {
      left: $element-width;
    }

    &--active {
      left: 0;
    }
  }
}
