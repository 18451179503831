$font-family-sans-serif: "Nunito", sans-serif !default;
// $font-family-sans-serif: "Open sans", -apple-system, BlinkMacSystemFont,
// 	"Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
// 	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base: $font-family-sans-serif !default;

$spacer: 1rem !default;

$font-weight-light: 100 !default;
$font-weight-normal: 300 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 600 !default;

$white: #fff !default;
$primary: #2b64cd !default;
$secondary: #2d9f9b !default;
$success: #2bcc73 !default;

$color-primary-0: #2b64cd; // Main Primary color */
$color-primary-1: #759be1;
$color-primary-2: #4d7dd6;
$color-primary-3: #0d4ec6;
$color-primary-4: #0a388d;

$color-secondary-1-0: #ff4220; // Main Secondary color (1) */
$color-secondary-1-1: #ff8c77;
$color-secondary-1-2: #ff664a;
$color-secondary-1-3: #ff2800;
$color-secondary-1-4: #d32100;

$color-secondary-2-0: #2d9f9b; // Main Secondary color (2) */
$color-secondary-2-1: #4daaa7;
$color-secondary-2-2: #44b1ad;
$color-secondary-2-3: #22928e;
$color-secondary-2-4: #097a77;

$color-complement-0: #ffb120; // Main Complement color */
$color-complement-1: #ffcf77;
$color-complement-2: #ffbf4a;
$color-complement-3: #ffa500;
$color-complement-4: #d38900;

$base-color: #4f4f4f;
$dark: $base-color;

$transition: 0.3s all 0s linear;
$border-radius: 4px;

$section-padding: 15px 0;
$section-lg-padding: 60px 0;

$navbar-padding-y: $spacer / 1 !default;

$navbar-nav-link-padding-x: 0.8rem !default;

$navbar-dark-color: rgba($white, 0.75) !default;
$navbar-dark-hover-color: $white !default;
$navbar-dark-active-color: rgba($white, 0.75) !default;
