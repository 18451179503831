* {
	&:focus {
		outline: none;
	}
}

html,
body {
	height: 100%;
}

body {
	overflow-x: hidden;
}

.swiper-pagination {
	left: 50%;
	transform: translateX(-50%) !important;

	&-bullet {
		margin: 0 5px;
	}
}

.signin-form {
	table {
		border-collapse: separate;
		border-spacing: 10px 15px;
		tr {
			th {
				text-align: right;
			}
		}
	}
}

form {
	.error {
		display: block;
		font-size: 12px;
		color: $color-secondary-1-4;
	}
}

.swiper-button-disabled {
	display: none;
}

.cke_editable {
	border: 1px dotted rgba(0, 0, 0, 0.3);
}

.nittro-transition-bar {
	&:before {
		z-index: 1001;
		background: $secondary;
	}
}

.nittro-flash-global-holder {
	z-index: 1000;
	left: 30px;
	bottom: 30px;
	top: auto;
	display: block;
	height: auto;
	width: auto;

	.nittro-flash {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		min-width: 300px;
	}
}

.nittro-dialog {
	&-inner {
		margin: auto !important;
		text-align: center;
	}

	&-button {
		border: 0;
		padding: 10px 15px;
		margin: 0 10px;
		width: 70px;
		background: $color-secondary-1-0;
		color: white;
		transition: $transition;

		&:hover {
			color: white;
			background: $color-secondary-1-4;
		}

		&-text {
			background: $secondary;

			&:hover {
				color: white;
				background: $color-secondary-2-4;
			}
		}
	}
}

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;

	&-info {
		color: #004085;
		background-color: #cce5ff;
		border-color: #b8daff;
	}

	.close {
		position: absolute;
		top: 5px;
		right: 5px;
		border: 0;
		background: white;
	}
}

.row-reverse {
	display: flex;
	flex-direction: row-reverse;
}

.col-12.col-md-12 .content-block--secondary-color {
	margin-top: 40px;
}
