.navbar {
  .navbar-nav {
    font-size: 1.2rem;
  }

  .navbar-collapse {
    transition: 3s height 0s linear;
  }

  @include media-breakpoint-down(md) {
    .navbar-collapse:not(.show) {
      height: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar.fixed-top {
    background: transparent !important;

    &.navbar--scroll,
    &.navbar--full {
      background: $primary !important;
      box-shadow: $box-shadow-lg !important;
    }

    &:not(.navbar--scroll) {
      padding: 1.6rem;
    }

    .navbar-nav {
      a {
        transition: $transition;
      }

      a:hover {
        color: $white;
        padding-top: 0.3rem;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar.fixed-top {
    box-shadow: $box-shadow-lg !important;
  }
}
