.table {
	&__image {
		width: 100px;
		height: 100px;

		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
		}
	}

	.grid-col-actions {
		min-width: 125px;
		text-align: right;
	}
}

#snippet-pagesGrid-rows {
	overflow-x: scroll;
}
