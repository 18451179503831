.references {
  padding-top: 120px;

  & + & {
    padding-top: 0px;
  }

  .row {
    justify-content: center;
  }

  [class*="col-"] {
    margin: 10px 0;
  }

  &__box {
    display: flex;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: white;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 20px;
    padding-bottom: 40px;
    transition: $transition;
    border-radius: $border-radius;
    top: 0;

    &:hover {
      text-decoration: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &__title {
    font-size: 16px;
    text-align: center;
    color: $primary;
    position: absolute;
    bottom: 0;
    margin: 10px;
  }

  &__img {
    display: flex;

    &--black {
      background: black;
    }

    img {
      max-width: 200px;
      max-height: 200px;
      display: block;
    }
  }
}

@include media-breakpoint-up(md) {
  .references {
    [class*="col-"] {
      margin: 15px 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .references {
    &.appear {
      .references__box {
        top: -30px;
        opacity: 0;
      }
    }
  }
}
