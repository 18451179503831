$element-width: 60px;

.page-wrapper,
.navbar.fixed-top {
  transition: $transition;
}

.page-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  //overflow-x: hidden;

  //padding-top: 83px;

  &--moved {
    &,
    .navbar.fixed-top {
      margin-left: $element-width;
    }

    & .top,
    & .cookies-info {
      width: calc(100% - #{$element-width});
    }
  }
}

@include media-breakpoint-up(lg) {
  $element-width: 230px;

  .page-wrapper {
    &--moved {
      &,
      .navbar.fixed-top {
        margin-left: $element-width;
      }

      & .top,
      & .cookies-info {
        width: calc(100% - #{$element-width});
      }
    }
  }
}
