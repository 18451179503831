.section-perex {
  font-size: 1rem;
  margin: 40px 0;
  text-align: center;
}

@include media-breakpoint-up(md) {
  .section-perex {
    width: 75%;
    margin: 40px auto;
  }
}
