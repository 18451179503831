.dragndrop {
  position: relative;
  z-index: 10;
  bottom: -5px;
  width: 100%;

  .dropzone-control {
    position: relative;
    border: 2px dashed $primary;
    background: transparent;
    transition: $transition;

    &.active {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .dropzone-file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropzone-file-label {
    display: block;
    margin: 0;
    text-align: center;
    padding: 20px 0;
    font-size: 20px;

    small {
      display: block;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .form-errors {
    margin: 0;
  }

  &.active {
    background: rgba(255, 255, 255, 0.25);
  }
}
