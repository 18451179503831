.pagination {
	list-style-type: none;
	display: flex;
	margin: 0 auto;
	justify-content: center;

	.page-item {
		float: left;
		border: 1px solid $primary;
		border-radius: 3px;
		margin: 0 5px;
		line-height: 1;

		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		a {
			display: block;
			color: white;
			background: $primary;
			padding: 7px 11px;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
