.section-title {
  position: relative;
  color: $dark;
  text-align: center;
  font-size: 2rem;
  padding-bottom: 1.5rem;

  &::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 3px;
    bottom: 0;
    left: calc(50% - 30px);
    background: currentColor;
  }
}
