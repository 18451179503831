.text-boxes {
  &__content {
    margin: 40px 0;
  }

  &__item {
    margin: 60px 0;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &--primary-color {
      .text-boxes__text {
        color: white;
        background: $primary;
      }
    }

    &--secondary-color {
      .text-boxes__text {
        color: white;
        background: $secondary;
      }
    }
  }

  &__title {
    position: relative;
    text-align: center;
    margin: 20px 0;
    padding-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      width: 60px;
      height: 3px;
      bottom: 0;
      left: calc(50% - 30px);
      background: currentColor;
    }
  }

  &__img {
    margin: 0 auto;
    text-align: center;
    width: 250px;
    height: 250px;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__text {
    line-height: 1.7;
    border-radius: $border-radius;
    padding: 10px 20px;

    p {
      margin: 20px 0;
    }
  }
}
