.side-items{
    position: absolute;
    z-index: 0;
    width: 100%;

    &__left{
        float: left;
        left: -50px;
    }

    &__right{
        float: right;
        right: -50px;
    }
}
