.brands {
  position: relative;
  background: #123f9b;
  background: linear-gradient(to right, #123f9b 0%, #2b64cd 100%);
  padding: 20px 0 60px;
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 110%;
    height: 100px;
    background: white;
    left: 0%;
    top: -72px;
    transform: rotate(1.5deg);
  }

  &::after {
    top: auto;
    bottom: -72px;
    transform: rotate(-1.5deg);
  }

  & + section {
    padding-top: 60px;
  }

  [class*="col-"] {
    padding-bottom: 60px;

    &:last-child {
      padding-bottom: 15px;
    }
  }

  .section-perex {
    color: white;
  }

  &__item {
    a {
      display: block;
      height: 80px;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
    }
  }
}

@include media-breakpoint-up(md) {
  .brands {
    padding: $section-lg-padding;

    [class*="col-"] {
      padding: 15px;
    }
  }
}
