.cookies-info {
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  text-align: left;
  padding: 5px;
  font-size: 14px;
  line-height: 30px;
  z-index: $zindex-fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  button {
    float: right;
    background: $secondary;
    border-radius: 6rem;

    &:hover {
      background: darken($secondary, 10%);
    }
  }
}
