.three-boxes {
  [class*="col-"] {
    padding-bottom: 15px;
  }

  &__item {
    border: 1px solid $gray-400;
    border-radius: $border-radius;
    background: white;

    &-header {
      @include clearfix;
      margin: 20px;
    }
  }

  &__avatar {
    float: left;
    width: 100px;
    height: 100px;
    border: 1px solid $gray-600;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
  }

  &__title {
    float: left;
    width: calc(100% - 120px);
    margin-left: 20px;
    margin-top: 25px;
  }

  &__text {
    margin: 20px;
    margin-top: 40px;
  }

  .item-title {
    font-size: 1.2rem;
  }

  .item-subtitle {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-up(md) {
  .three-boxes {
    [class*="col-"] {
      padding: 15px;
    }

    &__item {
      height: 100%;
    }
  }
}
