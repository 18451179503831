.content-block {
  padding: 10px 0;
  break-inside: avoid;
  position: relative;

  .row {
    margin-left: -5px;
    margin-right: -5px;

    div[class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__text {
    // font-weight: $light-weight;
    line-height: 1.7;
    //border-radius: $border-radius;
    padding: 10px 0px;
    text-align: justify;
    margin: 2px;

    p {
      margin: 20px 0;
    }
  }

  &__title {
    position: relative;
    text-align: center;
    margin: 20px 0;
    padding-bottom: 20px;
    font-weight: $font-weight-normal;

    &::after {
      content: "";
      position: absolute;
      width: 60px;
      height: 3px;
      bottom: 0;
      left: calc(50% - 30px);
      background: currentColor;
    }
  }

  &__content {
    ul {
      padding-left: 16px;
    }

    a[data-cke-saved-href] {
      padding: 5px 20px;
      background: $primary;
      color: white;
      border-radius: 50px;
      transition: $transition;

      &:hover {
        background: darken($primary, 10%);
        text-decoration: none;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__pic {
    position: relative;
    width: 50%;
    border: 2px solid transparent;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 15px auto;
    }

    &--first {
      width: 100%;
    }

    .admin-controls {
      top: 0;
      left: 3px;
      right: auto;

      &__button {
        float: left;
      }
    }
  }

  &--primary-color {
    .content-block__text {
      color: white;
      background: $primary;
      padding: 10px 20px;
    }
  }

  &--secondary-color {
    .content-block__text {
      color: white;
      background: $secondary;
      padding: 10px 20px;
    }
  }

  .text-align {
    &-left {
      text-align: left;
      /*
			h3 {
				text-align: left;
				&::after {
					display: none;
				}
			}
			*/
    }
    &-right {
      text-align: right;
      /*
			h3 {
				text-align: right;
				&::after {
					display: none;
				}
			}
			*/
    }
    &-center {
      text-align: center;
    }
    &-justify {
      text-align: justify;
    }
  }
}

@include media-breakpoint-up(lg) {
  .content-block .col-lg-6 {
    .content-block {
      &__pic {
        &--first {
          margin-top: 80px;
        }
      }
    }
  }
}
