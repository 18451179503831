.admin {
  &__wrapper {
    margin-top: 83px;
  }

  &__header {
    &-wrapper {
      margin-top: 10px;
      border-bottom: 1px solid $gray-200;

      h2 {
        margin-bottom: 0;
      }

      .subnav__button {
        display: table;
        margin-left: auto;
        padding-top: 10px;

        .btn {
          padding: 10px 20px;
        }
      }
    }
  }

  &__header + &__wrapper {
    margin-top: 0;
    padding-top: 0;

    .admin__content {
      padding-top: 30px;
    }
  }

  &__button {
    margin-top: 25px;
    a {
      display: block;
    }
  }

  &__img {
    img {
      display: block;
      max-width: 100%;
    }
  }
}

textarea {
  min-height: 150px;
}
