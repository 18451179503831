.login-form{
    margin: 60px 0;

    &__header{
        text-align: center;
    }

    &__content{
        display: table;
        margin: 0 auto;
    }


}
