.contact-boxes {
  [class*="col-"] {
    padding-bottom: 15px;
  }

  &__item {
    position: relative;
    padding: 20px;
    border: 1px solid #969696;
    border-radius: $border-radius;
    // font-weight: $light-weight;
    overflow: hidden;
    height: 100%;
    background: white;

    a {
      color: $base-color;
    }

    &--primary-color {
      background: $primary;
      color: white;

      .contact-boxes__flag {
        border: 0;
      }

      a {
        color: white;
      }
    }
  }

  &__flag {
    float: left;
    width: 80px;

    img {
      display: block;
      max-width: 100%;
      border: 1px solid #efefef;
    }
  }

  &__text {
    text-align: right;
    margin-bottom: 40px;

    strong {
      font-weight: $font-weight-bold;
    }
  }

  &__contact {
    position: relative;
    z-index: 1;
    padding-left: 40px;
  }

  &__img {
    position: absolute;
    bottom: -40px;
    left: 20px;
    width: 180px;
    height: 180px;
    z-index: 0;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-boxes {
    [class*="col-"] {
      padding: 15px;
    }
  }
}
