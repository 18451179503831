.navbar-toggler {
  position: relative;
  width: 40px;
  height: 40px;
  border: 0;
  outline: none !important;

  span {
    position: absolute;
    display: block;
    content: "";
    width: 28px;
    height: 2px;
    background: white;
    top: 20px;
    left: 8px;
    transition: 0s opacity 0.3s linear;
  }

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    width: 26px;
    height: 2px;
    background: white;
    top: 14px;
    left: 8px;
    //transition: $transition;
    transition: 0.3s top 0.3s linear, 0.3s transform 0s linear;
  }

  &:after {
    top: 26px;
  }

  &--close {
    span {
      opacity: 0;
    }

    &::before {
      transform: rotate(45deg);
      top: 20px;
      transition: 0.3s top 0s linear, 0.3s transform 0.3s linear;
    }
    &::after {
      transform: rotate(-45deg);
      top: 20px;
      transition: 0.3s top 0s linear, 0.3s transform 0.3s linear;
    }
  }
}
