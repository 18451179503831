@font-face {
  font-family: "urbitech";
  src: url("../fonts/urbitech.ttf?2d5izm") format("truetype"),
    url("../fonts/urbitech.woff?2d5izm") format("woff"),
    url("../fonts/urbitech.svg?2d5izm#urbitech") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "urbitech" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete:before {
  content: "\f014";
}
.icon-home:before {
  content: "\f015";
}
.icon-references:before {
  content: "\f022";
}
.icon-pages:before {
  content: "\f03a";
}
.icon-edit:before {
  content: "\f040";
}
.icon-show:before {
  content: "\f06e";
}
.icon-sign-in:before {
  content: "\f090";
}
.icon-sign-out:before {
  content: "\f090";
}
