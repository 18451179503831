html {
  scroll-behavior: smooth;
}

section {
  padding: $section-padding;
}

@include media-breakpoint-up(md) {
  section {
    padding: $section-lg-padding;
  }

  section + section {
    padding-top: 0;
  }
}
