.hero {
  position: relative;
  //min-height: calc(100vh - 83px);
  min-height: 100vh;
  color: white;
  //margin-top: 83px;

  &__flashes {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 100;
    color: black;
  }

  &__background {
    @include cover-img;

    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.05;
      //background: url('../assets/images/pattern-mrizka.png') top left repeat;
      background: $primary;
    }
  }

  &__box {
    position: relative;
    width: 100%;
    margin-bottom: 5rem;
    z-index: 9;

    .button--big {
      display: inline-block;
    }
  }

  &__img {
    //display: none;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    &--video {
      display: block;
      max-width: 100%;
      max-height: 100%;
      padding: 20px;
      padding-bottom: 40px;
    }
  }

  &__info {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 99;
  }

  &__changer {
    position: relative;
    min-height: calc(100vh - 83px);
    width: 100%;
    top: 0;
    padding-bottom: 70px;
    //background: #d9d9d9;

    & .hero__box {
      /*
			top: 120px;
			transition: $transition;
			transition-duration: 0.4s;
			*/
      position: relative;
      height: auto;
      top: 100px;
      padding: 10px;

      .container {
        position: relative;
      }
    }
  }

  .swiper-slide {
    .hero__content {
      opacity: 0;
      transition: $transition;
    }

    &-single {
      .hero__content {
        opacity: 1 !important;
      }
    }

    &-active {
      .hero__content {
        opacity: 1;
      }
    }
  }

  &__dragndrop {
    display: none;
    position: absolute;
    z-index: 10;
    width: 75%;
    bottom: 50px;
    right: 50px;

    .dropzone-control {
      position: relative;
      border: 2px dashed $color-primary-4;
      background: transparent;
      transition: $transition;
      background: rgba($color: #ffffff, $alpha: 0.25);

      &.active {
        background: rgba($color: #ffffff, $alpha: 0.4);
      }
    }

    .dropzone-file-input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .dropzone-file-label {
      display: block;
      margin: 0;
      text-align: center;
      padding: 20px 0;
      font-size: 20px;

      small {
        display: block;
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .form-errors {
      margin: 0;
    }

    &.active {
      background: rgba(255, 255, 255, 0.25);
    }
  }

  &__button {
    display: table;

    &-button {
      position: relative;
    }

    &-link {
      display: table;
      text-align: center;
      margin: 10px auto;
      color: white;

      &:hover {
        color: white;
        text-decoration: underline;
      }
    }

    &-reveal {
      position: absolute;
      top: 15px;
      right: -35px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 10px;
        height: 2px;
        transform: rotate(30deg);
        background: white;
        top: 50%;
        left: 1px;
      }

      &:after {
        transform: rotate(-30deg);
        left: 9px;
      }

      &--active {
        &:before {
          transform: rotate(-30deg);
        }
        &:after {
          transform: rotate(30deg);
        }
      }
    }

    &-dropdown {
      display: none;
      margin: 10px 0;

      p {
        background: rgba($color: #ffffff, $alpha: 0.2);
        padding: 5px;
      }

      &-input {
        position: relative;

        p {
          &:focus {
            & + div {
              display: none;
            }
          }
        }

        span {
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }

      &--active {
        display: block;
      }
    }
  }

  &__floating {
    display: none;
    &-item {
      position: absolute;
    }
  }

  .admin-controls {
    top: 93px;
  }

  .section-title {
    font-size: 1.5rem;
    color: white;
    text-align: left;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
    margin-bottom: 3rem;
    line-height: 1.5;
    height: auto;
    letter-spacing: 2px;

    &::after {
      display: none;
    }
  }

  .section-subtitle {
    font-size: 1.2rem;
  }

  .section-perex {
    font-size: 1rem;
    margin-bottom: 3rem;
    text-align: left;
    margin-left: 0;
    height: auto;
  }

  &--small {
    min-height: 66vh;
    position: relative;

    .hero__background {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0.25) 50%,
          rgba(0, 0, 0, 0.2) 100%
        );
        z-index: 1;
        opacity: 1;
      }
      &.without-shadow::before {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.05) 50%,
          rgba(0, 0, 0, 0.02) 100%
        );
      }

      &.ql-container {
        &::before {
          display: none;
        }
      }
    }

    .hero__box {
      position: absolute;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }

    .section-title {
      text-align: center;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 60px;
        height: 3px;
        bottom: 0;
        left: calc(50% - 30px);
        background: currentColor;
      }
    }

    .section-subtitle {
      text-align: center;
      position: relative;
    }
  }

  &--light {
    margin-top: 83px;
    height: auto;
    min-height: auto;

    .hero__box {
      position: relative;
      margin-bottom: 0;
    }

    .section-title {
      position: relative;
      color: $base-color;
      padding: 0;
      padding-bottom: 20px;
      margin: 20px;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 60px;
        height: 3px;
        bottom: 0;
        left: calc(50% - 30px);
        background: currentColor;
      }
    }
  }

  &-pagination {
    position: absolute;
    bottom: 10px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
    }
  }
}

@include media-breakpoint-up(md) {
  .hero {
    margin-bottom: 30px;

    &--light {
      padding-bottom: 0;

      .section-title {
        text-align: left;
        display: table;
      }
    }

    &__dragndrop {
      width: 33%;
      display: block;
    }

    &__img {
      //display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      max-width: 50%;
      padding: 20px;
    }

    &__floating {
      opacity: 0.4;

      &-item {
        animation: float 6s infinite linear;
      }

      &--right {
        .hero__floating-item {
          animation: float-right 6s infinite linear;
        }
      }
    }

    @keyframes float {
      0% {
        left: -500px;
      }
      100% {
        left: 100%;
      }
    }

    @keyframes float-right {
      0% {
        right: -500px;
      }
      100% {
        right: 100%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero {
    &__wrapper {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    &__img {
      padding: 0;
      left: auto;
      right: 0;
    }

    &__changer {
      min-height: 700px;
    }

    .section-title {
      font-size: 2.2rem;
    }

    .section-perex {
      font-size: 1.2rem;
    }
  }
}

@media (min-width: 2000px) {
  .hero {
    min-height: auto;
    height: 66vh;
    min-height: 700px;

    &--small {
      min-height: 66vh;
    }
  }
}

.hero-admin__button {
  position: absolute;
  top: 50%;
  left: 50%;
}

.admin__button {
  display: table;
  margin: 0 auto;

  .btn {
    padding: 10px 20px;
  }
}

.dropzone-control-preview-list {
  height: 5px;
  background: transparent;
}

.dropzone-control-preview-item-hide {
  display: none;
}

.dropzone-control-preview-progress {
  height: 5px;
  background: $primary;
}

.hero-wrapper {
  position: relative;
}

.header__wrapper {
  & + .hero__dragndrop {
    right: auto;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
}
